<template>
  <div v-if="!isUser">
    <b-row fluid class="mx-0 mb-2 mb-sm-3 justify-content-between">
      <h4 v-if="!isUser" class="d-flex align-items-center mb-0">
        {{ today }}
      </h4>
      <b-button type="button" variant="primary" id="resRefresh" @click="getResData()" class="mx-0">
        <Icon type="f" /> {{ $t('btn.f') }}
      </b-button>
    </b-row>
    <CustomerTable ref="table" suffix="resTabTdy"
        :query="resTabDef" :noHitText="noHitText || $t('res.rno')"
        showShow showAccept showReject showConfirm showDispute showResolve showPagination />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { utcToZonedTime } from 'date-fns-tz';
import CustomerTable from '@/components/reservations/CustomerTable';

export default {
  data() {
    return {
      today: null,
      resTabDef: { q: 'startAt:ge:{T},startAt:le:{T+1},userUuid4:n:{U}', s: '+startAt', p: '20' },
    }
  },
  created() {
    this.today = this.getToday();
  },
  computed: {
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
  },
  methods: {
    getResData() { 
      this.today = this.getToday();
      this.$refs['table'].getResData();
    },
    getToday() {
      return this.$d(utcToZonedTime(Date.now(), this.$store.state.settings.timezone), 'dl');
    },
  },
  props: {
    noHitText: {
      type: String,
      default: null,
    },
  },
  components: {
    CustomerTable,
    Icon,
  }
}
</script>
